import api from '../clients/axios';

const resource = '/api';

export default {

  async sendEmailToken(email) {
    const response = await api.get(
      `${resource}/register/send-email?email=${email}`,
    );
    return response.data;
  },

  async validateRegisterToken() {
    const response = await api.get(
      `${resource}/auth/register-token`,
    );
    return response.data;
  },

  async registerUser(payload) {
    return api.post(`${resource}/register/user`, payload);
  },

  savePhoto(payload) {
    return api.post(`${resource}/bucket/photo`, payload);
  },

  async getPhoto(path) {
    const response = await api.get(`${resource}/bucket/files?path=${path}`, {
      responseType: "blob",
      transformResponse: [
        function (data, headers) {
          const typeBlob = headers["content-type"];
          const blob = new window.Blob([data], {
            type: typeBlob,
          });
          const file = new File([blob], typeBlob, {
            type: typeBlob.slice(typeBlob.lastIndexOf("."), typeBlob.length),
          });
          return file;
        },
      ],
    });
    const reader = new FileReader();
    reader.readAsDataURL(response.data);
    const image = await new Promise((resolve) => {
      reader.onloadend = () => resolve(reader.result);
    });
    return image;
  },
  async getResume(path) {
    const response = await api.get(
      `${resource}/bucket/files?path=${path}`, {
        responseType: 'blob',
      },
    );
    return response;
  },

  async getProfessions() {
    const response = await api.get(
      `${resource}/professions`,
    );
    return response.data;
  },

  async getSpecializations(idProfession) {
    const response = await api.get(
      `${resource}/specializations?profession_id=${idProfession}`,
    );
    return response.data;
  },

  async getAreas(idSpecialization) {
    const response = await api.get(
      `${resource}/areas?specialization_id=${idSpecialization}`,
    );
    return response.data;
  },

  async getLevels() {
    const response = await api.get(
      `${resource}/levels`,
    );
    return response.data;
  },

  async getSectors() {
    const response = await api.get(
      `${resource}/sectors`,
    );
    return response.data;
  },

  async registerFreelancer(payload) {
    return api.post(`${resource}/register/freelancer`, payload);
  },
  async getFreelancer() {
    const response = await api.get(`${resource}/freelancers`);
    return response.data;
  },

  /*
  async getAll() {
    const response = await api.get(`${resource}`);
    return response.data;
  },

  async getFreelancer(id) {
    const response = await api.get(`${resource}/${id}`);
    return response.data;
  },

  create(payload) {
    return api.post(`${resource}`, payload);
  },

  update(payload, id) {
    return api.put(`${resource}/${id}`, payload);
  },

  delete(id) {
    return api.delete(`${resource}/${id}`);
  },
  */
};

import router from '@/router';
import api from './axios';

export default function setup() {
  if (router.currentRoute.name !== 'ApplicantExpertise') {
    api.interceptors.request.use(
      (config) => {
        const axiosConfig = config;
        const token = localStorage.getItem('brains-register-token');
        if (token) {
          axiosConfig.headers.Authorization = `Bearer ${token}`;
        }
        return axiosConfig;
      },
      (err) => Promise.reject(err),
    );
  }
}

/*
import api from './axios';

const responseMock = {
  data: {
    access_token: 'xxxxsssssddededededede',
  },
};

const injectToken = async (config) => {
  try {
    // const response = await UserRepository.getUser(1);
    const newConfig = config;
    newConfig.headers.authorization = `Bearer ${responseMock.data.access_token}`;
    return newConfig;
  } catch (error) {
    throw new Error('Unauthorized');
  }
};

api.interceptors.request.use(injectToken);
*/

import Repository from '../../services/repositories/RepositoryFactory';

const EvaluatorRepository = Repository.service('evaluators');

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getFreelancer(_, payload) {
      try {
        const response = await EvaluatorRepository.getFreelancer(payload);
        return response;
      } catch (error) {
        return console.error(error);
      }
    },
    async getProfileById(_, userId) {
      try {
        const response = await EvaluatorRepository.getProfileById(userId);
        return response;
      } catch (error) {
        return console.error(error);
      }
    },
    async registerEvaluator(_, payload) {
      try {
        const response = await EvaluatorRepository.registerEvaluator(payload);
        return response;
      } catch (error) {
        return error;
      }
    },
    async evaluateFreelancer(_, payload) {
      try {
        const response = await EvaluatorRepository.evaluateFreelancer(payload);
        return response;
      } catch (error) {
        return error;
      }
    },
  },
};

<template>
  <div class="static-buttons">
    <Button
      icon="pi pi-sign-out"
      label="Logout"
      class="p-button-rounded"
      @click="handleLogout"
    />
  </div>
</template>

<script>
import Button from "primevue/button";

export default {
  name: "Logout",
  components: { Button },
  methods: {
    handleLogout() {
      localStorage.clear();
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/* Style of the button to add a new row from the available trucks view */
.static-buttons {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: fixed;
  bottom: 40px;
  right: 40px;
  transition: all 300ms ease 0ms;
  z-index: 99;
}
.p-button {
  background: $primary-color;
  border-color: $primary-color;
  &:hover {
    background: $primary-color-lighter;
    border-color: $primary-color-lighter;
  }
}
</style>

import UserRepository from './UserRepository';
import FreelancerRepository from './FreelancerRepository';
import EvaluatorRepository from './EvaluatorRepository';

const repositories = {
  users: UserRepository,
  freelancers: FreelancerRepository,
  evaluators: EvaluatorRepository,
};

export default {
  service: (name) => repositories[name],
};

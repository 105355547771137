<template>
  <div>
    <div class="app-background"></div>
    <Toast />
    <Logout v-if="!($route.name === 'Signup') && !($route.name === 'Login') && !($route.name === 'Home')" />
    <div
      class="container-logo"
      v-if="!($route.name === 'Signup') && !($route.name === 'Login')"
    >
      <img
        src="@/assets/images/brains-logo.svg"
        alt="Brains logo"
        class="container-logo__logo"
      />
    </div>
    <div class="view-router-container">
      <router-view />
    </div>
  </div>
</template>

<script>
import Logout from "./components/Logout.vue";

export default {
  components: {
    Logout,
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
  font-family: $primary-font;
  letter-spacing: 0.001rem;
  font-size: 15.5px;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  min-height: 100vh;
}

.app-background {
  position: fixed;
  background: url("./assets/images/brains_background.png") no-repeat center
    center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.view-router-container {
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  /*  height: calc(95vh - 90px); */
  margin-bottom: 5rem;
}
*::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.3);
}
.container-logo {
  height: 85px;
  padding: 20px 30px 10px 30px;
  &__logo {
    height: 100%;
  }
}
</style>

import Repository from '../../services/repositories/RepositoryFactory';

const UserRepository = Repository.service('users');

export default {
  namespaced: true,
  state: {
    logedUsername: '',
    logedUserRole: '',
  },
  // ----------------------------------------------------------------------------------
  getters: {
    getLogedUsername(state) {
      return state.logedUsername;
    },
    getLogedUserRole(state) {
      return state.logedUserRole;
    },
  },
  // ----------------------------------------------------------------------------------
  mutations: {
    setLogedUsername(state, logedUsername) {
      state.logedUsername = logedUsername;
    },
    setLogedUserRole(state, logedUserRole) {
      state.logedUserRole = logedUserRole;
    },
  },
  // ----------------------------------------------------------------------------------
  actions: {
    async login(_, payload) {
      try {
        const response = await UserRepository.login(payload);
        return response;
      } catch (error) {
        return error;
      }
    },
    async getProfile(_) {
      try {
        const response = await UserRepository.getProfile();
        return response;
      } catch (error) {
        return error;
      }
    },
  },
};

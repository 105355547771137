import api from '../clients/axios';

const resource = '/api';

export default {

  async login(payload) {
    return api.post(`${resource}/auth/access-token`, payload);
  },

  async getProfile() {
    return api.get(`${resource}/freelancers/profile`);
  },
};

import Repository from '../../services/repositories/RepositoryFactory';

const freelancersRepo = Repository.service('freelancers');

export default {
  namespaced: true,
  state: {
    freelancer: 'freelancer1',
  },
  // ----------------------------------------------------------------------------------
  getters: {
    getFreelancer(state) {
      return state.user;
    },
  },
  // ----------------------------------------------------------------------------------
  mutations: {
    setFreelancer(state, payload) {
      state.freelancer = payload;
    },
  },
  // ----------------------------------------------------------------------------------
  actions: {

    async sendEmailToken(_, email) {
      try {
        const response = await freelancersRepo.sendEmailToken(email);
        return response;
      } catch (error) {
        return error;
      }
    },

    async validateToken() {
      try {
        const response = await freelancersRepo.validateRegisterToken();
        return response;
      } catch (error) {
        return error;
      }
    },

    async registerUser(_, payload) {
      try {
        const response = await freelancersRepo.registerUser(payload);
        return response;
      } catch (error) {
        return error;
      }
    },

    async savePhoto(_, payload) {
      try {
        const response = await freelancersRepo.savePhoto(payload);
        return response;
      } catch (error) {
        return error;
      }
    },

    async getPhoto(_, path) {
      try {
        const response = await freelancersRepo.getPhoto(path);
        return response;
      } catch (error) {
        return error;
      }
    },

    async getResume(_, path) {
      try {
        const response = await freelancersRepo.getResume(path);
        return response;
      } catch (error) {
        return error;
      }
    },

    async getProfessions(_) {
      try {
        const response = await freelancersRepo.getProfessions();
        return response;
      } catch (error) {
        return error;
      }
    },

    async getSpecializations(_, idProfession) {
      try {
        const response = await freelancersRepo.getSpecializations(idProfession);
        return response;
      } catch (error) {
        return error;
      }
    },

    async getAreas(_, idSpecialization) {
      try {
        const response = await freelancersRepo.getAreas(idSpecialization);
        return response;
      } catch (error) {
        return error;
      }
    },

    async getLevels(_) {
      try {
        const response = await freelancersRepo.getLevels();
        return response;
      } catch (error) {
        return error;
      }
    },

    async getSectors(_) {
      try {
        const response = await freelancersRepo.getSectors();
        return response;
      } catch (error) {
        return error;
      }
    },

    async registerFreelancer(_, payload) {
      try {
        const response = await freelancersRepo.registerFreelancer(payload);
        return response;
      } catch (error) {
        return error;
      }
    },

    /*
    async getUsers({ commit }) {
      // commit("setLoading", true);
      try {
        const response = await UserRepository.getAll();
        const users = response;
        commit('SET_USERS', users);
        return response;
      } catch (error) {
        return error;
      } finally {
        // commit("setLoading", false);
      }
    },
    */
  },
};

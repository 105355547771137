import api from '../clients/axios';

const resource = '/api';

export default {
  async getFreelancer(params) {
    const response = await api.get(`${resource}/evaluators/freelancers`, { params });
    return response.data;
  },
  async getProfileById(userId) {
    const response = await api.get(`${resource}/freelancers/${userId}/profile`);
    return response;
  },

  async registerEvaluator(payload) {
    return api.post(`${resource}/register/Evaluator`, payload);
  },

  async evaluateFreelancer(params) {
    return api.post(`${resource}/evaluators/evaluate/${params.id}?approved=${params.approved}`);
  },

};

import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeRoute from './modules/home';
import LoginRoute from './modules/login';
import ApplicantDetailsRoute from './modules/applicantDetails';
import ApplicantsListRoute from './modules/applicantsList';
import CompleteAccountSetupFormRoute from './modules/CompleteAccountSetupForm';
import RegistrateModerator from './modules/registrateModerator';
import ContactDataRoute from './modules/contactData';
import ApplicantEducationRoute from './modules/applicantEducation';
import ApplicantExpertiseRoute from './modules/applicantExpertise';
import VerifyEmailRoute from './modules/verifyEmail';
import ConfirmDataRoute from './modules/confirmData';
import SignupRoute from './modules/signup';
import AdministratorRoute from './modules/administrator';

Vue.use(VueRouter);

const AppRoute = [
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/:catchAll(.*)*',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "pagenotfound" */ '../views/PageNotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...HomeRoute,
    ...LoginRoute,
    ...ApplicantDetailsRoute,
    ...ApplicantsListRoute,
    ...CompleteAccountSetupFormRoute,
    ...RegistrateModerator,
    ...ContactDataRoute,
    ...ApplicantEducationRoute,
    ...ApplicantExpertiseRoute,
    ...VerifyEmailRoute,
    ...ConfirmDataRoute,
    ...SignupRoute,
    ...AdministratorRoute,
    ...AppRoute,
  ],
});

export default router;

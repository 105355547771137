import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import freelancer from './modules/freelancer';
import evaluator from './modules/evaluator';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    freelancer,
    evaluator,
  },
});

// Primevue
import PrimeVue from 'primevue/config';
import Dialog from 'primevue/dialog';
import Card from 'primevue/card';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import Chips from 'primevue/chips';
import FileUpload from 'primevue/fileupload';
import Fieldset from 'primevue/fieldset';
import Checkbox from 'primevue/checkbox';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import Calendar from 'primevue/calendar';
import InputNumber from 'primevue/inputnumber';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import Vue from 'vue';

Vue.component('Dialog', Dialog);
Vue.component('Card', Card);
Vue.component('Dropdown', Dropdown);
Vue.component('MultiSelect', MultiSelect);
Vue.component('Chips', Chips);
Vue.component('FileUpload', FileUpload);
Vue.component('Fieldset', Fieldset);
Vue.component('Checkbox', Checkbox);
Vue.component('Toast', Toast);
Vue.component('Calendar', Calendar);
Vue.component('InputNumber', InputNumber);
Vue.component('DataTable', DataTable);
Vue.component('Column', Column);
Vue.component('ColumnGroup', ColumnGroup);

Vue.use(PrimeVue);
Vue.use(ToastService);
